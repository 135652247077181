import { uuidv4 } from 'lib/utils/uuidv4'

export class InvoicePaymentItem {
    id: string
    invoice_id: number | null
    amount: number | null
    amount_outstanding: number | null

    constructor(invoice_id?: number, amount_outstanding?: number) {
        this.id = uuidv4()
        this.invoice_id = invoice_id || null
        this.amount = null
        this.amount_outstanding = amount_outstanding || null
    }
}
