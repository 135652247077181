import { useQuery } from '@tanstack/react-query'
import { FC, useMemo } from 'react'
import { map } from 'lodash'

import { Spinner, ErrorBanner } from 'ds/components'
import { RecordPaymentForm } from './RecordPaymentForm'

import { customerService } from 'lib/services/customerService'
import { invoiceService } from 'lib/services/invoiceService'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

type Props = {
    invoices?: any[]
    customer_id?: number
    bankTransaction?: any
    hideCreditPaymentOption?: boolean

    onSuccess: () => void
}

export const RecordPaymentFormLoader: FC<Props> = ({
    invoices,
    bankTransaction,
    hideCreditPaymentOption,
    onSuccess,
    customer_id,
}) => {
    const customerQueryParams = useMemo(
        () => ({ customer_ids: customer_id?.toString() }),
        [customer_id]
    )
    const customerQuery = useQuery({
        queryKey: ['customer', customerQueryParams],
        queryFn: () => customerService.getAll(customerQueryParams),
    })
    const customerError = getErrorFromQuery(customerQuery.error, customerQuery.data)

    const invoiceQueryParams = useMemo(
        () => ({
            components: 'cfdi,contact',
            invoice_ids: invoices ? map(invoices, 'invoice_id').join(',') : undefined,
        }),
        [invoices]
    )

    const invoicesQuery = useQuery({
        queryKey: ['invoice', 'v2', invoiceQueryParams],
        queryFn: () => invoiceService.getInvoicesV2(invoiceQueryParams),
        enabled: !!invoiceQueryParams.invoice_ids,
        gcTime: 0,
    })

    const invoiceError = getErrorFromQuery(invoicesQuery.error, invoicesQuery.data)

    if (customerQuery.isLoading || invoicesQuery.isLoading) {
        return <Spinner />
    } else if (customerError || invoiceError || !customerQuery.data) {
        return (
            <ErrorBanner>
                Ha ocurrido un error: {customerError || invoiceError || 'Algo salio mal'}
            </ErrorBanner>
        )
    }

    const customers = customerQuery.data.customers

    return (
        <RecordPaymentForm
            bankTransaction={bankTransaction}
            customer={customers.length === 1 ? customers[0] : undefined}
            customers={customers}
            hideCreditPaymentOption={hideCreditPaymentOption}
            invoices={invoicesQuery.data?.invoices || []}
            onSuccess={onSuccess}
        />
    )
}
