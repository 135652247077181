export const formatCurrency = (num, decimals = 2, currency) => {
    if (!num && num !== 0) return num
    if (isNaN(decimals)) decimals = 2

    const n = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: Math.min(decimals, 2),
        maximumFractionDigits: decimals,
    }).format(num)

    if (currency) return `${n} ${currency}`

    return n
}
