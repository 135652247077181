import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

export const generalService = {
    getExchangeRate: async (comercioExterior?: boolean) =>
        makeApiRequest<GetExchangeRate>({
            url: '/exchange-rate',
            queryParams: { for_comercio_exterior: comercioExterior ? 1 : 0 },
        }),
}

type GetExchangeRate = {
    exchangeRate: {
        date: string
        rate: string
    }
}
