import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { Banner, Button, Checkbox, Tooltip } from 'ds/components'

import { cfdiService } from 'lib/services/cfdiService'
import {
    getCfdiIds,
    getInvoicePaymentsMap,
    getInvoicesWithApplicableCfdis,
    getMultipleCfdisError,
} from '../utils'
import { dateStringToDate } from 'lib/utils/dateStringUtils'

export const CfdiCreditModule = ({ onChange, formData, disabled }) => {
    const { mutate, isPending } = useMutation({
        mutationFn: cfdiService.postCfdiEgreso,
        onError: () => toast.error('Algo salió mal.'),
        onSuccess: (resp) => {
            if (resp.error) return toast.error(resp.error)

            toast.success(resp.message)
            window.open(resp.pdf_url, '_blank')
        },
    })

    const invoicesWithApplicableCfdis = useMemo(
        () => getInvoicesWithApplicableCfdis(formData.invoices, 'E'),
        [formData.invoices]
    )

    const multipleCfdisError = useMemo(
        () => getMultipleCfdisError(invoicesWithApplicableCfdis, true),
        [invoicesWithApplicableCfdis]
    )

    if (invoicesWithApplicableCfdis.length === 0) return ''

    if (multipleCfdisError.length) {
        return (
            <Banner variant="warning">
                <p>
                    Se encontraron cobros con multiples facturas CFDI de ingreso. Para
                    poder generar CFDI de egreso, cada cobro solo debe tener una.
                </p>
                {multipleCfdisError.map((err) => (
                    <p key={err}>{err}</p>
                ))}
            </Banner>
        )
    }

    const getCfdiEgresoText = (invoices) => {
        if (invoices.length === 1) return `Aplica al cobro ${invoices[0].invoice_num}`
        return `Aplica a los cobros ${invoices.map((i) => i.invoice_num).join(', ')}`
    }

    return (
        <div>
            <div className="inline inline-md ">
                <Checkbox
                    checked={formData.generate_cfdi_egreso}
                    onChange={(v) => onChange(v)}
                >
                    Generar{' '}
                    <Tooltip trigger="nota de crédito CFDI">
                        {getCfdiEgresoText(invoicesWithApplicableCfdis)}
                    </Tooltip>
                </Checkbox>
            </div>
            <div className="inline">
                <Button
                    size="sm"
                    variant="primaryText"
                    isLoading={isPending ? 'Generando...' : undefined}
                    disabled={disabled}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        mutate({
                            amount_paid: formData.amount_paid,
                            currency: formData.currency,
                            exchange: formData.exchange,
                            invoices: formData.invoices,
                            date_received: dateStringToDate(formData.payment_date, {
                                endOfDay: true,
                            }),
                            payment_form: formData.paymentFormObject.code,
                            invoice_payments_map: getInvoicePaymentsMap(formData),
                            customer_id: formData.customer.customer_id,

                            mode: 'preview',
                            cfdi_ids: getCfdiIds(invoicesWithApplicableCfdis, true),
                        })
                    }}
                >
                    → Ver preview
                </Button>
            </div>
        </div>
    )
}
