import { FC } from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { TextLink } from 'ds/components'

import { generalService } from 'lib/services/generalService'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'

type Props = {
    onChange: (value: number) => void
    getForComercioExterior?: boolean
}

export const GetExchangeRateHelp: FC<Props> = ({ onChange, getForComercioExterior }) => {
    const { mutate } = useMutation({
        mutationFn: generalService.getExchangeRate,
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            onSuccess: ({ exchangeRate, message }) => {
                toast.success(message || 'Tasa de cambio obtenida con éxito')
                onChange(Number(exchangeRate.rate))
            },
        }),
    })

    return (
        <div className="d-flex gap-1">
            <span>Tipo de Cambio</span>·
            <TextLink onClick={() => mutate(!!getForComercioExterior)}>
                {getForComercioExterior ? 'DOF Día Anterior' : 'Banxico (FIX)'}
            </TextLink>
        </div>
    )
}
