import { Customer } from 'lib/models/customer'
import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/customers'

export const customerService = {
    getAll: async (queryParams?: GetCustomersAllParams): Promise<GetCustomerData> =>
        makeApiRequest({
            url,
            method: 'GET',
            queryParams,
        }),
    getCustomer: async ({ customer_id }) =>
        makeApiRequest({ url: `${url}/${customer_id}` }),
    create: async (body) => makeApiRequest({ url, method: 'POST', body }),
    putCustomer: async (body) =>
        makeApiRequest({
            url: `${url}/${body.customer_id}`,
            method: 'PUT',
            body,
        }),
    putCustomerValidation: async (body) =>
        makeApiRequest({
            url: `${url}/${body.customer_id}/validation`,
            method: 'PUT',
            body,
        }),
    getCustomerStatement: async ({
        customer_id,
        ...queryParams
    }: GetCustomerStatementParams) =>
        makeApiRequest({
            url: `${url}/${customer_id}/statement`,
            queryParams,
        }),
    downloadCustomerStatement: async ({
        customer_id,
        customer_name,
        ...queryParams
    }: GetCustomerStatementParams & { customer_name: string }) => {
        const filename = `Estado de Cuenta - ${customer_name}.pdf`
        return downloadFile({
            url: `${url}/${customer_id}/statement`,
            queryParams: { ...queryParams, pdf: true },
            filename,
        })
    },
    getCustomerReceivablesStatement: async ({
        customer_id,
        ...queryParams
    }: GetCustomerReceivablesStatementParams) =>
        makeApiRequest({
            url: `${url}/${customer_id}/receivables-statement`,
            queryParams,
        }),
    downloadCustomerReceivablesStatement: async ({
        customer_id,
        customer_name,
        ...queryParams
    }: GetCustomerReceivablesStatementParams & { customer_name: string }) => {
        const filename = `Estado de Cuenta - ${customer_name}.pdf`
        return downloadFile({
            url: `${url}/${customer_id}/receivables-statement`,
            queryParams: { ...queryParams, download_pdf: true },
            filename,
        })
    },
    getCustomerPortalLink: async (customer_id: number) =>
        makeApiRequest<{ portal_id: string }>({
            url: `${url}/${customer_id}/portal-link`,
        }),
}

type GetCustomerStatementParams = {
    customer_id: number
    start: Date | undefined | null
    end: Date | undefined | null
    tag_ids: number[]
    start_date: string | null
    end_date: string | null
    pdf_url?: boolean
}

type GetCustomersAllParams = {
    organization_id?: number | null
    customer_ids?: string
}

type GetCustomerData = {
    customers: Customer[]
    exchange_rate: number
    error?: any
}

type GetCustomerReceivablesStatementParams = {
    customer_id: number
    has_cfdi: string | null
    hide_future_invoices: string | null
    pdf_url?: boolean
}
