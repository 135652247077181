import { FC } from 'react'
import { Label } from 'ds/components'
import { formatCurrency } from 'lib/utils/formatCurrency'
import cx from 'classnames'
import styles from './style.module.scss'

type Props = {
    currency?: string
    amount: number | null
    label?: string
    className?: string
}

export const CurrencyKeyValue: FC<Props> = ({ className, currency, amount, label }) => {
    return (
        <div className={className}>
            {label ? <Label>{label}</Label> : null}
            <div className={cx(styles.container)}>
                {amount ? formatCurrency(amount, 2, currency) : '-'}
            </div>
        </div>
    )
}
