import { format } from 'date-fns'
import { es } from 'date-fns/locale'

export const formatDateShort = (date) => {
    if (!date) return date
    const d = new Date(date)
    if (isNaN(d.getTime())) return date

    return format(d, 'd MMM y', { locale: es })
    // ex. 3 oct 2022
}
